import axios from 'axios'
import { headers } from './header'

export const SolicitudTokenService = {
  getLista,
  postSolicitud,
  getOpcionesPlanSolicitudToken
}

const URL_API_ADMIN = `${process.env.VUE_APP_API_HOST_ADMIN}/api/v1`
const URL_API_VENTA = `${process.env.VUE_APP_API_HOST_VENTA}/api/v1`
const URL_API_DB_TASS = `${process.env.VUE_APP_API_DB_TASS}/v1`



async function getOpcionesPlanSolicitudToken() {
  
  const requestOptions = {
    method: 'GET',
    url: `${URL_API_DB_TASS}/planes/sucursales/500`,
    headers: headers(),
  }

  return axios(requestOptions)
    .then(res => res.data)
    .catch(err => err.response)
    
}

async function getLista(data) {
  const requestOptions = {
    method: 'GET',
    url: `${URL_API_VENTA}/anulacionPoliza/${data}`,
    headers: headers(),
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}

async function postSolicitud(data) {
  const requestOptions = {
    method: 'POST',
    url: `${URL_API_VENTA}/solicitud/token`,
    headers: headers(),
    data,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}
