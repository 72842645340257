<template>
  <div class="auth-wrapper auth-v1 mt-15">
    <div class="auth-inner">
      <v-row class="match-height">
        <v-col cols="12">
          <v-container>
            <v-row justify="space-around">
              <v-card width="800">
                <v-card-title class="white--text mt-0 fondo-card">
                  <v-avatar size="80">
                    <v-icon class="ml-2 white--text">
                      {{ icons.mdiAccountKey }}
                    </v-icon>
                  </v-avatar>
                  <p
                    class="ml-8 white--text"
                  >
                    Solicitud Token
                  </p>
                </v-card-title>

                <v-card-text>
                  <v-form
                    ref="formclave"
                  >
                    <v-row
                      class="mb-15 mt-5 ml-3 mr-3 "
                    >
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          v-model="usuario"
                          label="Usuario"
                          required
                          :rules="[validators.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="8"
                      >
                        <v-text-field
                          v-model="correo"
                          label="Correo "
                          :rules="[validators.required, validators.emailValidator]"
                          type="email"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          v-model="rut"
                          label="Rut Asegurado"
                          persistent-hint
                          required
                          :rules="[validators.required, rutValido]"
                          :rut="true"
                          @input="escucharRut"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="8"
                      >
                        <v-select
                          v-model="producto"
                          :items="optionsProducto"
                          item-text="nombre"
                          item-value="codigo"
                          label="Producto"
                          :rules="[validators.required]"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-form>
                  <div class="d-flex justify-end mb-3 mr-3">
                    <v-btn
                      color="primary"
                      class="me-2"
                      :loading="loading"
                      :disabled="loading"
                      @click="solicitud"
                    >
                      Solicitud
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-row>
          </v-container>
        </v-col>
        <v-snackbar
          v-model="isFormSubmittedSnackbarVisible2"
          right
          top
          :color="colorSnackbar"
        >
          {{ textSnackbar }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="isFormSubmittedSnackbarVisible2 = false"
            >
              <v-icon>{{ icons.mdiCloseThick }}</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiAccountKey, mdiCloseThick, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { required, emailValidator } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import { SolicitudTokenService } from '../service/solicitudTokenService'

export default {
  components: {},
  setup() {
    const { router } = useRouter()
    const formclave = ref(null)
    const dialog = ref(false)
    const usuario = ref('')
    const correo = ref('')
    const rut = ref('')
    const producto = ref('')

    const isFormSubmittedSnackbarVisible2 = ref(false)
    const colorSnackbar = ref('')
    const textSnackbar = ref('')
    const loading = ref(false)
    const optionsProducto = ref([])

    return {
      icons: {
        mdiAccountKey,
        mdiCloseThick,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      validators: {
        required,
        emailValidator,
      },
      rutValido: value => {
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(value)) {
          return 'Rut inválido.'
        }

        return true
      },
      dialog,
      formclave,
      usuario,
      correo,
      rut,
      producto,

      isFormSubmittedSnackbarVisible2,
      colorSnackbar,
      textSnackbar,
      router,
      loading,
      optionsProducto
    }
  },
  mounted() {
    this.getOpcionesProductos()
  },
  computed: {
    escucharRut() {
      if (this.validRut(this.rut)) {
        return this.rut
      }

      return false
    },
  },
  methods: {
    async getOpcionesProductos() {
      
      await SolicitudTokenService.getOpcionesPlanSolicitudToken()
        .then(data => {
          this.optionsProducto = data.result.map(x => {
            return {
              codigo: x.codigoPlan,
              nombre: x.plan
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
        
    },
    validRut(value) {
      // const valor = value.replace(/[^A-Z0-9]/gi, '')
      let valor = this.rut.replace('.', '')
      valor = valor.replace('-', '')
      const cuerpo = valor.slice(0, -1)
      let dv = valor.slice(-1).toUpperCase()

      this.rut = `${cuerpo}-${dv}`

      let suma = 0
      let multiplo = 2
      for (let i = 1; i <= cuerpo.length; i++) {
        const index = multiplo * valor.charAt(cuerpo.length - i)
        suma += index
        if (multiplo < 7) {
          multiplo += 1
        } else {
          multiplo = 2
        }
      }
      const dvEsperado = 11 - (suma % 11)

      // Casos Especiales (0 y K)
      dv = dv === 'K' ? 10 : dv
      dv = dv === 0 ? 11 : dv

      // Validar que el Cuerpo coincide con su Dígito Verificador
      if (dvEsperado != dv) {
        return false
      }

      return true
    },
    async solicitud() {
      const isFormValid = this.formclave.validate()

      if (!isFormValid) return
      const data = {
        usuario: this.usuario,
        email: this.correo,
        rutAsegurado: this.rut,
        producto: this.producto,
      }
      this.loading = true
      await SolicitudTokenService.postSolicitud(data).then(resp => {
        if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = resp.data.message
          this.colorSnackbar = 'success'
          this.loading = false
          this.resetData()
        } else {
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = resp.data.message
          this.colorSnackbar = 'error'
          this.loading = false
        }
      })
    },
    resetData() {
      this.rut = ''
      this.producto = ' '
    },
  },
}
</script>
<style scoped>
.fondo-card {
  background: linear-gradient(98deg, #67afed, var(--v-primary-base) 94%);
  color: white;
}
.v-btn--fab.v-size--x-small {
  height: 20px !important;
  width: 20px !important;
}
.card-header-title {
  /* background-color: rgb(20 20 20 / 25%);*/
  background: #56ccf2;
  background: -webkit-linear-gradient(to left, #2f80ed, #56ccf2);
  background: linear-gradient(to left, #2f80ed, #56ccf2);
  color: white;
}
.card-header-title-text {
  color: #ffffff;
}
</style>
