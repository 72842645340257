import { render, staticRenderFns } from "./formSolicitud-token.vue?vue&type=template&id=0c42d790&scoped=true&"
import script from "./formSolicitud-token.vue?vue&type=script&lang=js&"
export * from "./formSolicitud-token.vue?vue&type=script&lang=js&"
import style0 from "./formSolicitud-token.vue?vue&type=style&index=0&id=0c42d790&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c42d790",
  null
  
)

export default component.exports